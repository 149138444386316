import React from 'react';

import aboutImg from './base-about.jpg';


export default class About extends React.Component {
    render() {
        return (
            <section id="About" className="bg-companyBlue-100">
                <div className="container py-20">
                    <div className="mb-8">
                        <p className="text-3xl font-medium text-companyBlue-600 text-center">About Us</p>
                    </div>
                    <div className="flex flex-wrap items-center">
                        <div className="md:flex-1 md:mr-3">
                            <img src={aboutImg}
                                 className="max-w-full h-auto border border-1 shadow-lg shadow-gray-400/30 rounded"
                                 alt="About Us"/>
                        </div>
                        <div className="md:flex-1 md:ml-3">
                        <h2>About Metatelecom</h2>
  <p>Metatelecom is a new way to communicate in the metaverse. It uses the Ethereum blockchain, NFTs, and cryptography to provide a secure, private, and decentralized way to make calls and send messages.</p>
  <p>With Metatelecom, you can:</p>
  <ul>
    <li>Make and receive calls to anyone in the metaverse, regardless of their location.</li>
    <li>Send and receive text messages, photos, and videos.</li>
    <li>Create your own custom NFT phone number.</li>
    <li>Use your NFT phone number to access exclusive features and content.</li>
  </ul>
  <p>Metatelecom is the future of communication in the metaverse. It is secure, private, and decentralized, and it offers a wide range of features and benefits.</p>
  <h3>Features</h3>
  <p>Here are some of the features of Metatelecom:</p>
  <ul>
    <li>Secure: Metatelecom uses the Ethereum blockchain to provide a secure and private way to communicate. Your calls and messages are encrypted and cannot be intercepted by anyone.</li>
    <li>Private: Metatelecom does not store any of your personal information. Your phone number is stored on the blockchain, which is a public ledger, but your name and other identifying information is not.</li>
    <li>Decentralized: Metatelecom is not owned by any one company or organization. It is a decentralized network that is controlled by its users.</li>
  </ul>
  <h3>Benefits</h3>
  <p>Here are some of the benefits of using Metatelecom:</p>
  <ul>
    <li>Make and receive calls to anyone in the metaverse, regardless of their location.</li>
    <li>Send and receive text messages, photos, and videos.</li>
    <li>Create your own custom NFT phone number.</li>
    <li>Use your NFT phone number to access exclusive features and content.</li>
  </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
