import React from 'react';

import { GiPlayerNext, GiStrongbox, GiSmartphone } from "react-icons/gi";

import ProjectBlock from './ProjectBlock';

export default class ProjectList extends React.Component {
    projects = [
        {
            icon: GiSmartphone,
            direction: 'The Metatelecom Phone Service',
            description: 'This is the core product of the Metatelecom ecosystem. It allows users to make and receive calls to anyone in the metaverse, regardless of their location.</li>'
        },
        {
            icon: GiPlayerNext,
            direction: 'The Metatelecom Messaging Service',
            description: 'This service allows users to send and receive text messages, photos, and videos to anyone in the metaverse.'
        },
        {
            icon: GiStrongbox,
            direction: 'The Metatelecom NFT Phone Numbers',
            description: 'These are unique, non-fungible tokens that represent phone numbers in the metaverse. Users can purchase NFT phone numbers to customize their identity and access exclusive features and content.'
        }
    ];

    render() {
        return (
            <section id="Projects" className="px-2 clean-block features">
                <div className="container py-20">
                    <div className="mb-8">
                        <p className="text-3xl font-medium text-companyBlue-600 text-center">Metatelecom Ecosystem and Product</p>
                    </div>
                    <div className="flex flex-wrap justify-center">
                        {
                            this.projects.map((project, index) => <ProjectBlock entity={project} key={index}/>)
                        }
                    </div>
                </div>
            </section>
        );
    }
}