import React from 'react';

import Mission from './Mission';
import About from './About';
import ProjectList from './Projects';
import Contact from './Contact';

export default class Content extends React.Component {

    render() {
        return (
            <main>
                <Mission/>
                <About/>                
                <ProjectList/>
                <Contact/>
            </main>
        )
    }
}