import React from 'react';


export default class Mission extends React.Component {
    render() {
        return (
            <section className="bg-[url('components/Content/Mission/base-bg.jpg')] bg-no-repeat bg-cover">
              <div className="pt-32 pb-16 md:w-full lg:w-3/4 xl:w-1/2 text-center whitespace-normal mx-auto">
                <h2 className="text-white md:text-3xl sm:text-3xl font-medium">Mission</h2 >
                <p className="text-white">To provide a secure, private, and decentralized way to communicate in the metaverse.</p>

                <h2 className="text-white md:text-3xl sm:text-3xl font-medium">Vision</h2>
                <p className="text-white ">To be the leading provider of communication services in the metaverse.</p>

                <h2 className="text-white md:text-3xl sm:text-3xl font-medium">Values</h2>
                <ul>
                  <li className="text-white font-small">Security</li>
                  <li className="text-white ">Privacy</li>
                  <li className="text-white ">Decentralization</li>
                </ul>

                <p className="text-white pb-7">We believe that the Metatelecom is the future of communication in the metaverse. We are committed to providing our users with a secure, private, and decentralized way to communicate.</p>

                <button
                    className="mb-2 inline-block align-middle text-center select-none border font-normal whitespace-no-wrap rounded  no-underline bg-companyBlue-100 text-companyBlue-600 hover:bg-companyBlue-150 py-3 px-4 leading-tight text-xl"
                    type="button" data-bs-target="#contact">
                    <a href="#Contact">
                        Contact Us
                    </a>
                </button>
                </div>
            </section>
        );
    }
}
